import styles from 'pages/GalateaDifference/About/components/LatestEvents/LatestEvents.module.scss';
import { EventItem } from 'api/Events/types';
import useEvents from 'app/hooks/useEvents';
import { galateaDifferenceUrls } from 'app/routing';
import Loader from 'components/Loader/Loader';
import { Heading } from 'components/Typography';
import LatestEvent from 'pages/GalateaDifference/About/components/LatestEvents/components/LatestEvent/LatestEvent';

import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LatestEvents: React.FC = () => {
  const { t } = useTranslation();
  const { fetchEvents, events, isLoading } = useEvents();

  useEffect(() => {
    const abortController = new AbortController();
    fetchEvents('', abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchEvents ]);

  const eventList: EventItem[] = useMemo(() => {
    let eventItems: EventItem[];
    eventItems = events.upcomingEvents.slice(0, 3);

    if (eventItems.length < 3) {
      eventItems = [ ...eventItems, ...events.pastEvents.slice(0, 3 - eventItems.length) ];
    }

    return eventItems;
  }, [ events ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.latestEvents}>

      <div className={styles.latestEvents__topLine}>
        <Heading variant="H4">{t('galateaDifference.latestEvents')}</Heading>

        <Link to={galateaDifferenceUrls.events}>
          <Heading variant="H6">{t('welcomePage.showAll')}</Heading>
        </Link>
      </div>

      {eventList.map(event => <LatestEvent event={event} key={event.id} />)}

    </div>
  );
};

export default LatestEvents;
