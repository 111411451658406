import styles from 'pages/GalateaDifference/About/About.module.scss';
import { Page } from 'app/types/ProductCatalog';
import AboutUs from 'pages/GalateaDifference/About/components/AboutUs/AboutUs';
import LatestEvents from 'pages/GalateaDifference/About/components/LatestEvents/LatestEvents';
import OurNews from 'pages/GalateaDifference/About/components/OurNews/OurNews';
import OurPublications from 'pages/GalateaDifference/About/components/OurPublications/OurPublications';

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const About: React.FC<Page> = ({ setPageHeader }) => {
  useEffect(() => setPageHeader(undefined));

  return (
    <>
      <Helmet title="The Galatea Difference" />

      <div className={styles.about}>

        <AboutUs />

        <div className={styles.about__bottomPanel}>

          <OurPublications />

          <OurNews />

          <LatestEvents />

        </div>

      </div>
    </>
  );
};

export default About;
