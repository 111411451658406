import styles from 'pages/GalateaDifference/About/components/OurPublications/OurPublications.module.scss';
import usePublications from 'app/hooks/usePublications';
import { galateaDifferenceUrls } from 'app/routing';
import Loader from 'components/Loader/Loader';
import { Body, Heading } from 'components/Typography';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OurPublications: React.FC = () => {
  const { t } = useTranslation();
  const { fetchPublications, publications, isLoading } = usePublications();

  useEffect(() => {
    const abortController = new AbortController();
    fetchPublications('', '', abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchPublications ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.ourPublications}>
      <div className={styles.ourPublications__topLine}>
        <Heading variant="H4">{t('welcomePage.ourPublications')}</Heading>

        <Link to={galateaDifferenceUrls.publications}>
          <Heading variant="H6">{t('welcomePage.showAll')}</Heading>
        </Link>
      </div>

      <div className={styles.ourPublications__links}>

        {publications.items.slice(0, 4).map(item => (
          <Link to={galateaDifferenceUrls.publicationsDetailUrl(item.meta.slug)} className={styles.ourPublications__link} key={item.id}>
            <Body variant="M">{item.title}</Body>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default OurPublications;
