import { ProductType } from 'app/types/Wagtail';

const products = '/products';
const productDetail = `${products}/:productId`;
const panels = `${products}/panels`;
const panelsWithSort = (sortBy: ProductType) => `${panels}/?sortBy=${sortBy}`;
const panelDetail = `${panels}/:panelId`;
const panelDetailUrl = (panelId: string) => `${panels}/${panelId}`;
const subsetComparing = `${products}/comparing/:panelId/:subsetId`;
const subsetComparingUrl = (panelId: string, subsetId: string) => `${products}/comparing/${panelId}/${subsetId}/`;
const nextGenerationSequencing = `${products}/next-generation-sequencing`;
const healthRiskScoring = `${products}/health-risk-scoring`;

const urls = {
  products,
  productDetail,
  panels,
  panelsWithSort,
  panelDetail,
  panelDetailUrl,
  subsetComparing,
  subsetComparingUrl,
  nextGenerationSequencing,
  healthRiskScoring,
};

export default urls;
