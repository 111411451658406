const galateaDifference = '/galatea-difference';
const publications = `${galateaDifference}/publications`;
const publicationsDetail = `${publications}/:publicationSlug`;
const publicationsDetailUrl = (publicationSlug: string) => `${publications}/${publicationSlug}`;
const news = `${galateaDifference}/news`;
const newsDetail = `${news}/:newsSlug`;
const newsDetailUrl = (newsSlug: string) => `${news}/${newsSlug}`;
const events = `${galateaDifference}/events`;
const eventsDetail = `${events}/:eventSlug`;
const eventsDetailUrl = (publicationSlug: string) => `${events}/${publicationSlug}`;
const leadership = `${galateaDifference}/leadership`;
const licenses = `${galateaDifference}/licenses`;
const compliance = `${galateaDifference}/compliance`;

const urls = {
  galateaDifference,
  publications,
  publicationsDetail,
  publicationsDetailUrl,
  news,
  newsDetail,
  newsDetailUrl,
  events,
  eventsDetail,
  eventsDetailUrl,
  leadership,
  licenses,
  compliance,
};

export default urls;
