import styles from 'pages/GalateaDifference/About/components/OurPublications/OurPublications.module.scss';
import useNews from 'app/hooks/useNews';
import { galateaDifferenceUrls } from 'app/routing';
import Loader from 'components/Loader/Loader';
import { Body, Heading } from 'components/Typography';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OurNews: React.FC = () => {
  const { t } = useTranslation();
  const { fetchNews, news, isLoading } = useNews();

  useEffect(() => {
    const abortController = new AbortController();
    fetchNews('', '', abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchNews ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.ourPublications}>
      <div className={styles.ourPublications__topLine}>
        <Heading variant="H4">{t('welcomePage.galateaInNews')}</Heading>

        <Link to={galateaDifferenceUrls.news}>
          <Heading variant="H6">{t('welcomePage.showAll')}</Heading>
        </Link>
      </div>

      <div className={styles.ourPublications__links}>

        {news.items.slice(0, 4).map(item => (
          <Link to={galateaDifferenceUrls.newsDetailUrl(item.meta.slug)} className={styles.ourPublications__link} key={item.id}>
            <Body variant="M">{item.title}</Body>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default OurNews;
