import styles from 'pages/Provider/ClinicalGeneticTesting/ClinicalGeneticTesting.module.scss';
import { providersUrls } from 'app/routing';
import { Page, BreadcrumbItem } from 'app/types/ProductCatalog';
import PageHeader from 'components/PageHeader/PageHeader';
import { Body } from 'components/Typography';

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const ClinicalGeneticTesting: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();
  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: t('menuItems.howItWorks'), link: providersUrls.howItWorks },
    { text: t('menuItems.clinicalVideo') },
  ], [ t ]);

  useEffect(() => setPageHeader(
    <PageHeader
      title={t('menuItems.clinicalVideo')}
      breadcrumbItems={breadcrumbItems}
    />,
  ), [ setPageHeader, breadcrumbItems, t ]);

  return (
    <>
      <Helmet title={t('menuItems.clinicalVideo')} />

      <Body variant="L">
        Our genetic testing catalog empowers you to explore genetic variation and pathways of biological importance to help you make informed
        decisions about health and health management.
      </Body>

      <Link
        to="https://www.youtube.com/@GalateaBio"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="YouTube"
        className={styles.clinicalVideo}
      />
    </>
  );
};

export default ClinicalGeneticTesting;
