import styles from 'pages/ProductCatalog/components/Panel/components/PanelMeta/PanelMeta.module.scss';
import { TestingPanel } from 'api/TestingPanels/types';
import { grayscale400 } from 'app/consts/colors';
import { getIconByType } from 'app/helpers/icons';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { patientsUrls, providersUrls } from 'app/routing';
import Button from 'components/Button/Button';
import { Caption, Heading } from 'components/Typography';

import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  panel: TestingPanel
}

const PanelMeta: React.FC<Props> = ({ panel }) => {
  const isPatientVersion = useIsPatientVersion();
  const { productType, subsets, methodologiesAmount, genesAmount } = panel;
  const isCommon = productType === null;
  const isPrs = productType === 'PRS';
  const url = isPatientVersion ? patientsUrls.howItWorks : providersUrls.howItWorks;

  return (
    <div className={styles.panelMeta}>
      {!isPrs && subsets.length > 0 && (
        <div className={styles.panelMeta__block}>
          <Heading variant="H4">{subsets.length}</Heading>
          <Caption variant="XS" style={{ color: grayscale400 }}>Subsets</Caption>
        </div>
      )}

      {!isCommon && methodologiesAmount > 0 && (
        <div className={styles.panelMeta__block}>
          <Heading variant="H4">{methodologiesAmount}</Heading>
          <Caption variant="XS" style={{ color: grayscale400 }}>Methodologies</Caption>
        </div>
      )}

      {isCommon && genesAmount > 0 && (
        <div className={styles.panelMeta__block}>
          <Heading variant="H4">{genesAmount}</Heading>
          <Caption variant="XS" style={{ color: grayscale400 }}>Genes in total</Caption>
        </div>
      )}

      <Link to={url} className={styles.panelMeta__howToOrder}>
        <Button size="L" icon={getIconByType('shopping-cart-add')}>How to order</Button>
      </Link>
    </div>
  );
};

export default PanelMeta;
