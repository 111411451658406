import 'components/Footer/Footer.scss';
import { ReactComponent as FullLogo } from 'app/assets/FullLogo.svg';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { galateaDifferenceUrls, legalUrls, patientsUrls, providersUrls } from 'app/routing';
import { ReactComponent as Instagram } from 'components/Footer/assets/instagram.svg';
import { ReactComponent as LinkedIn } from 'components/Footer/assets/linkedin.svg';
import { ReactComponent as X } from 'components/Footer/assets/x.svg';
import { ReactComponent as YouTube } from 'components/Footer/assets/youtube.svg';
import { Body, Heading } from 'components/Typography';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';

interface Props {
  showTopBorder?: boolean;
}

const Footer: React.FC<Props> = ({ showTopBorder = true }) => {
  const { t } = useTranslation();
  const isPatients = useIsPatientVersion();
  const linkClasses = cn('footer__link', { footer__link_patients: isPatients });

  return (
    <footer className={cn('footer', { footer_topBorder: showTopBorder })} style={{}}>

      <div className="footer__content">

        <Link to="/">
          <FullLogo className="footer__logo" />
        </Link>

        <Body variant="S">{t('general.14350CommerceWay')}</Body>

        <div className={cn('footer__contacts', { footer__contacts_patients: isPatients })}>
          <div>
            <Body>{t('general.email')}</Body>
            <Body>{t('general.phone')}</Body>
            <Body>{t('general.fax')}</Body>
          </div>

          <div>
            <Body><Link to="mailto:info@galatea.bio">info@galatea.bio</Link></Body>
            <Body><Link to="tel:+1 754 233 3350">+1 754 233 3350</Link></Body>
            <Body><Link to="tel:+1 866 382 1156">+1 866 382 1156</Link></Body>
          </div>
        </div>

        <div className="footer__social">
          <Link to="https://x.com/GalateaBio" target="_blank" rel="noopener noreferrer" aria-label="X"><X /></Link>
          <Link
            to="https://www.linkedin.com/company/galatea-bio-inc/mycompany/?viewAsMember=true"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <LinkedIn />
          </Link>
          <Link to="https://www.instagram.com/galatea.bio/" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><Instagram /></Link>
          <Link to="https://www.youtube.com/@GalateaBio" target="_blank" rel="noopener noreferrer" aria-label="YouTube"><YouTube /></Link>
        </div>

        <Body variant="S">© 2024 {t('general.galateaBioInc')}</Body>

      </div>

      <div className="footer__menu">

        <div className="footer__providerAndPatient">
          <div className="footer__column">
            <Heading variant="H6">{t('general.provider')}</Heading>
            <Link to={providersUrls.howItWorks} className="footer__link"><Body>{t('menuItems.howItWorks')}</Body></Link>
            <Link to={providersUrls.orderTest} className="footer__link"><Body>{t('general.orderTest')}</Body></Link>
            <Link to={providersUrls.resources} className="footer__link"><Body>{t('menuItems.resources')}</Body></Link>
          </div>

          <div className="footer__column">
            <Heading variant="H6">{t('general.patient')}</Heading>
            <Link to={patientsUrls.howItWorks} className="footer__link footer__link_patients"><Body>{t('menuItems.howItWorks')}</Body></Link>
            <Link to={patientsUrls.submitSample} className="footer__link footer__link_patients"><Body>{t('general.submitSample')}</Body></Link>
            <Link to={patientsUrls.faq} className="footer__link footer__link_patients"><Body>{t('menuItems.patientsFAQ')}</Body></Link>
          </div>
        </div>

        <div className="footer__differenceAndLegal">
          <div className="footer__column">
            <Heading variant="H6">{t('menuItems.galateaDifference')}</Heading>
            <Link to={galateaDifferenceUrls.galateaDifference} className={linkClasses}><Body>{t('menuItems.aboutUs')}</Body></Link>
            <Link to={galateaDifferenceUrls.publications} className={linkClasses}><Body>{t('menuItems.publications')}</Body></Link>
            <Link to={galateaDifferenceUrls.leadership} className={linkClasses}><Body>{t('menuItems.leadershipTeam')}</Body></Link>
            <Link to={galateaDifferenceUrls.events} className={linkClasses}><Body>{t('menuItems.events')}</Body></Link>
            <Link to={galateaDifferenceUrls.news} className={linkClasses}><Body>{t('menuItems.news')}</Body></Link>
            <Link to={galateaDifferenceUrls.compliance} className={linkClasses}><Body>{t('menuItems.compliance')}</Body></Link>
            <Link to={galateaDifferenceUrls.licenses} className={linkClasses}><Body>{t('menuItems.licenses')}</Body></Link>
          </div>

          <div className="footer__column">
            <Heading variant="H6">{t('menuItems.legal')}</Heading>
            <Link to={legalUrls.cookiePolicy} className={linkClasses}><Body>{t('menuItems.cookiePolicy')}</Body></Link>
            <Link to={legalUrls.privacyPolicy} className={linkClasses}><Body>{t('menuItems.privacyPolicy')}</Body></Link>
            <Link to={legalUrls.hipaaNotice} className={linkClasses}><Body>{t('menuItems.hipaa')}</Body></Link>
            <Link to={legalUrls.dataGovernance} className={linkClasses}><Body>{t('menuItems.data')}</Body></Link>
            <Link to={legalUrls.researchDataGovernance} className={linkClasses}><Body>{t('menuItems.research')}</Body></Link>
          </div>
        </div>

      </div>

      <div className="footer__contactUs">

        <Heading variant="H6">{t('menuItems.contactUs')}</Heading>

        <div className="footer__column">
          <div>
            <Body>{t('menuItems.clinical')}</Body>
            <Link to="mailto:info@galatea.bio" className={linkClasses}><Body>info@galatea.bio</Body></Link>
          </div>

          <div>
            <Body>{t('menuItems.technical')}</Body>
            <Link to="mailto:support@galatea.bio" className={linkClasses}><Body>support@galatea.bio</Body></Link>
          </div>

          <div>
            <Body>{t('menuItems.compliance')}</Body>
            <Link to="mailto:compliance@galatea.bio" className={linkClasses}><Body>compliance@galatea.bio</Body></Link>
          </div>

          <div>
            <Body>{t('menuItems.theBiobank')}</Body>
            <Link to="mailto:bbofa@galatea.bio" className={linkClasses}><Body>bbofa@galatea.bio</Body></Link>
          </div>
        </div>
      </div>

    </footer>
  );
};

export default Footer;
