import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { catalogUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import HealthRiskScoring from 'pages/ProductCatalog/components/Product/components/HealthRiskScoring/HealthRiskScoring';
import NextGenerationSequencing from 'pages/ProductCatalog/components/Product/components/NextGenerationSequencing/NextGenerationSequencing';
import useProduct from 'pages/ProductCatalog/components/Product/hooks/useProduct';

import React, { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Product: React.FC<Page> = ({ setPageHeader }) => {
  const { productId } = useParams<{ productId: string }>();
  const { fetchProduct, product, isLoading } = useProduct();
  const { title, productType, secondaryButtonText } = product;
  const isPrs = productType === 'PRS';

  useEffect(() => {
    const abortController = new AbortController();
    if (productId) {
      fetchProduct(productId, abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [ fetchProduct, productId ]);

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: 'Products', link: catalogUrls.products },
    { text: title },
  ], [ title ]);

  const firstMenuText = isPrs ? 'What is a PRS?' : 'Genome vs Exome';
  const secondMenuText = isPrs ? 'Clinical advantages' : 'Key Factors';
  const thirdMenuText = isPrs ? 'How Galatea does it?' : 'Analysis Comparing';

  const sectionTitles = useMemo(
    () => [ firstMenuText, secondMenuText, thirdMenuText ],
    [ firstMenuText, secondMenuText, thirdMenuText ],
  );
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles);

  const TestingCatalogButton = useMemo(() => (
    <Link to={catalogUrls.panelsWithSort(productType)}>
      <Button size="L" type="ghost">{secondaryButtonText}</Button>
    </Link>
  ), [ productType, secondaryButtonText ]);

  useEffect(() => {
    if (isLoading) {
      setPageHeader(undefined);
    } else {
      setPageHeader(<PageHeader title={title} sidebarItems={sidebarItems} breadcrumbItems={breadcrumbItems} rightColumn={TestingCatalogButton} />);
    }
  }, [ setPageHeader, breadcrumbItems, TestingCatalogButton, sidebarItems, title, isLoading ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title={title} />
      <PageWithSidebar sidebarItems={sidebarItems}>
        {isPrs ? <HealthRiskScoring sectionItems={sectionItems} /> : <NextGenerationSequencing sectionItems={sectionItems} />}
      </PageWithSidebar>
    </>
  );
};

export default Product;
